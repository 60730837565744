import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GOING_THROUGH_ERROR_INTERCEPTOR_OPTION } from '@core/interceptors/error.interceptor';
import { IApiResponse } from '@shared/model/api-models';
import { catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDataLogin, IParamForgetPassword, IParamLogin, IParamLogin2fa, IParamOptSendWithoutToken, IParamOtpValidateWithoutToken, IParamRegister, IParamResetPassword, IResponseRegister } from '../models/models';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  private baseUrl = environment.apiEndpoint;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  postLogin(params: IParamLogin) {
    const url = this.baseUrl + '/accounts/login';

    return this.http.post<IApiResponse<IDataLogin>>(url, { data: params }, { observe: 'response' }).pipe(
      tap(response => { this.storageService.login(response.body!.data); }),
      catchError(response => of(response))
    );
  }

  postLogin2fa(params: IParamLogin2fa) {
    const url = this.baseUrl + '/accounts/login-2fa';

    return this.http.post<IApiResponse<IDataLogin>>(url, { data: params }, { observe: 'response' }).pipe(
      tap(response => { this.storageService.login(response.body!.data); }),
      catchError(response => of(response))
    );
  }

  postForgetPassword(params: IParamForgetPassword) {
    const url = this.baseUrl + '/accounts/forget-password';

    return this.http.post<IApiResponse<string>>(url, { data: params }, { observe: 'response' }).pipe(
      catchError(response => of(response)),
    );
  }

  postResetPassword(params: IParamResetPassword) {
    const url = this.baseUrl + '/accounts/reset-password';

    return this.http.post<IApiResponse<string>>(url, { data: params }, { observe: 'response' }).pipe(
      catchError(response => of(response)),
    );
  }

  postRegister(params: IParamRegister) {
    const url = this.baseUrl + '/accounts/register';

    return this.http.post<IApiResponse<IResponseRegister>>(url, { data: params }, { observe: 'response' }).pipe(
      tap(response => { this.storageService.login(response.body!.data); }),
      catchError(response => of(response)),
    );
  }

  postOtpValidateWithoutToken(params: IParamOtpValidateWithoutToken) {
    const url = this.baseUrl + '/otp/validate-without-token';

    return this.http.post<IApiResponse<any>>(url, { data: params }, { observe: 'response' }).pipe(
      catchError(response => of(response))
    );
  }

  postOptSendWithoutToken(params: IParamOptSendWithoutToken) {
    const url = this.baseUrl + '/otp/send-without-token';

    return this.http.post<IApiResponse<any>>(url, { data: params }, { observe: 'response' }).pipe(
      catchError(response => of(response))
    );
  }

  getAuthorization() {
    const url = this.baseUrl + '/accounts/current-user-id';

    const context = new HttpContext();
    context.set(GOING_THROUGH_ERROR_INTERCEPTOR_OPTION, {
      notAllowed: true
    });

    return this.http.get<IApiResponse<number>>(url, { context }).pipe(
      map(response => !response.data || true),
      catchError(() => of<false>(false)),
    );
  }
}
