import { HttpContextToken, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ErrorHandlingService } from '@core/services/error-handling.service';
import { catchError, throwError } from 'rxjs';

export const GOING_THROUGH_ERROR_INTERCEPTOR_OPTION = new HttpContextToken<{ notAllowed: boolean, expiresIn?: number; }>(() => ({ notAllowed: false }));

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const errorHandlingService = inject(ErrorHandlingService);

  if (req.context.get(GOING_THROUGH_ERROR_INTERCEPTOR_OPTION).notAllowed) {
    return next(req);
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (req.headers.get('NO_ALERT')) {
        return throwError(() => error);
      }
      errorHandlingService.handleError(error);
      return throwError(() => error);
    })
  );
};
